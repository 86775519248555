<template>
  <v-container>
    <v-row
      :v-show="!loading"
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        Na tvoju emailovú adresu bolo zaslané nové heslo.
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { APIService } from "@/http/APIService.js";
const apiService = new APIService();


export default {
  props: {
    source: String,
  },
  data() {
    return {
      loading: false,
      alert: false,
    };
  },


  computed: {
  },

  components: {
  },

  mounted: function () {
    this.resetPassword();
  },


  methods: {
    resetPassword() {
      this.loading = true;
      apiService.resetPasswordFinal(this.$route.query.email, this.$route.query.code).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>